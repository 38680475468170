import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import React from "react";

import { SliceVariants } from "@components/SliceVariants";

import { components } from "./variants";
/**
 * Props for `Carousel`.
 */
export type CarouselProps = SliceComponentProps<Content.CarouselSlice>;

/**
 * Component for "Carousel" Slices.
 */
const Carousel = ({ slice, ...config }: CarouselProps): JSX.Element => (
  <SliceVariants {...config} components={components} slice={slice} />
);
export default Carousel;
