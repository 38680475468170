import { CarouselSliceDefaultPrimary, Simplify } from "prismicio-types";
import React from "react";

import { CollectionSlider } from "@components/CollectionSlider";
import { AlgoliaResult } from "@integrations/algolia/types";

import { CarouselProps } from "../..";

/** Extend the type to include additional content not included in Prismic */
export type CarouselPrimary = Simplify<CarouselSliceDefaultPrimary> & {
  data: AlgoliaResult[];
};

const Carousel = ({ slice }: CarouselProps): JSX.Element | null => {
  const { primary } = slice;
  const { name, link, data } = primary as CarouselPrimary;

  if (!data || !name || !link) {
    return null;
  }

  return (
    <CollectionSlider
      collectionName={name as string}
      collectionType="algolia"
      data={data}
      location="slice"
      slug={link as string}
    />
  );
};

export default Carousel;
